import React, { useEffect, useState } from "react";
import { StatusScreen } from "@mercadopago/sdk-react";
import PlaceholderStatus from "./PlaceholderStatus";

export default function StatusPage({ paymentId }) {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setLoader(false);
  }, [loader]);

  const onError = async (error) => {
    console.log(error);
  };

  const onReady = async () => setLoader(false);

  const customization = {
    visual: {
      showExternalReference: true,
      hideStatusDetails: false,
      hideTransactionDate: false,
      hideSubtitle: false,
    },
  };

  return (
    <div>
      <div className="mt-4">
        {loader ? (
          <PlaceholderStatus />
        ) : (
          <StatusScreen
            initialization={{ paymentId }} // Pasa la propiedad como objeto
            onReady={onReady}
            onError={onError}
            customization={customization.visual} // Pasa la propiedad visual de settings directamente
          />
        )}
      </div>
    </div>
  );
}
