import * as React from "react"
import Navbar from "components/global/navbar"
import Layout from "components/global/layout"
import PageTitle from "components/global/page-title"
import {
  ArrowLeftIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/outline"
import { Disclosure } from "@headlessui/react"
import { classNames, isEmptyObject } from "components/helpers"
import { useStore } from "app/store/store"
import { Link } from "gatsby"
import { sendEmail } from "../app/services/backend"
import { useEffect, useState } from "react"
import { getVisitPage } from "../components/helpers"
import StatusPage from "../components/mercado-pago/StatusPage"
import Warning from "../components/Warning"
import Loader from "../components/mercado-pago/Placeholder"

export const capitalizeData = (string) => {
  const capitalizeString = string.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase()
  })
  return capitalizeString
}

const PagoCompletado = () => {
  const [globalState] = useStore()
  const [styles, setStyles] = useState(false)
  const [paymentId, setPaymentId] = useState()
  const [status, setStatus] = useState()
  const guide = !isEmptyObject(globalState.guide) ? globalState?.guide : null
  const [showLoader, setShowLoader] = useState(true)

  useEffect(() => {
    setShowLoader(true)
    if (JSON.parse(sessionStorage.getItem("paymentData"))) {
      const { id, status } = JSON.parse(sessionStorage.getItem("paymentData"))
      if (status === 'rejected') {
        setPaymentId('')
      } else {
        setPaymentId(id)
        setStatus(status)
      }
      if (guide) {
        setShowLoader(false)
      }
    }
  }, [paymentId])

  const origen = guide?.origen || null
  const destino = guide?.destino || null
  useEffect(() => {
    sessionStorage.setItem(
      "allDataDetails",
      JSON.stringify({
        origen: origen?.estado,
        destino: destino?.estado,
        sender: globalState?.senderDetails,
        receiver: globalState?.receiverDetails,
        costo: globalState?.guide?.costo,
        paquete: globalState?.guide?.paquete,
      })
    )
  }, [globalState])

  return (
    <>
      <Navbar />
      <Layout>
        {status === 'approved' ? (
          guide ? (
            <>
              {showLoader ?
                <Loader textInfo={'Cargando Guía...'} />
                :
                <Guide guide={guide} destino={destino} origen={origen} paymentId={paymentId} styles={styles} />
              }
            </>
          ) : (
            !guide && status === 'approved' &&
            <StatusPage paymentId={paymentId} />
          )
        ) : (
          <StatusPage paymentId={paymentId} />
        )}
        {!guide && status !== 'approved' && (
          <>
            <PageTitle>¡AÚN NO HAS HECHO UNA COTIZACIÓN!</PageTitle>
            <Warning />
          </>
        )}
      </Layout>
    </>

  )
}

export default PagoCompletado


const Guide = ({ guide, destino, origen, paymentId, styles }) => {
  return (
    <>
      <PageTitle>¡Gracias por elegir Aerocharter!</PageTitle>
      <StatusPage paymentId={paymentId} />
      <h2 className="uppercase text-2xl font-display font-bold text-center text-gray-800 my-8">
        Pago Completado
      </h2>
      <div className="bg-gray-200 bg-opacity-30 rounded-md rounded-b-none text-primary-dark-blue px-6 py-6 md:grid md:grid-cols-2 md:gap-6">
        <div>
          <div className="mb-6">
            <div className="text-base font-display pb-6">
              <span className="uppercase font-bold text-primary-light-green">
                id de rastreo:
              </span>
              <Link to={`/rastreo?guia=${guide._id}`}>
                <span className="font-normal"> {guide._id}</span>
              </Link>
              <br />
            </div>
            <div>
              <div className="flex items-center justify-between capitalize">
                <div className="">{`${origen?.estado || capitalizeData(origen?.ciudad)
                  } - ${origen?.cp || origen?.codigo_postal}`}</div>
                <div>
                  <ChevronRightIcon className="h-4 w-4 text-gray-700" />
                </div>
                <div>{`${destino?.estado || capitalizeData(destino?.ciudad)
                  } - ${destino?.cp || destino?.codigo_postal}`}</div>
              </div>
            </div>
          </div>{" "}
          {/* end section 1 */}
          <div className="mb-6">
            <div className="uppercase text-primary-light-red font-display font-bold text-lg">
              Total: ${parseFloat(guide.costo).toFixed(2)} mxn
            </div>
            <div>
              {
                // map items with key
                // prepare the data and return a map from it
                [
                  {
                    type: "fecha estimada de entrega",
                    value: guide.fecha_entrega,
                  },
                  {
                    type: "Tipo de mercancía",
                    value: capitalizeData(guide.paquete.descripcion),
                  },
                  { type: "alto", value: `${guide.paquete.alto} cm` },
                  { type: "ancho", value: `${guide.paquete.ancho} cm` },
                  {
                    type: "profundidad",
                    value: `${guide.paquete.profundidad} cm`,
                  },
                  { type: "peso", value: `${guide.paquete.peso} kg` },
                ].map((item, key) => (
                  <div
                    key={key}
                    className="flex items-center justify-between font-display text-xs uppercase text-primary-gray"
                  >
                    <div className="font-bold">{item.type}:</div>
                    <div className="font-normal">
                      {capitalizeData(item.value)}
                    </div>
                  </div>
                ))
              }
            </div>
          </div>{" "}
          {/* end section 2 */}
        </div>
        <div className="mb-6 space-y-5 flex justify-center sm:justify-between flex-wrap">
          <div className="uppercase font-display font-bold text-lg text-primary-light-green mb-6 w-full">
            Detalles de la orden
          </div>
          <div className=" w-full block sm:flex justify-between">
            <Disclosure defaultOpen={true} key="item-1">
              {({ open }) => (
                <div>
                  <Disclosure.Button className="flex items-center">
                    <span className="uppercase font-display text-xs">
                      Quien Envía
                    </span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "-rotate-180" : "rotate-0",
                        "w-4 h-4 transform ml-5"
                      )}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="space-y-1.5 mb-6">
                    <div>
                      <span className="font-bold">Nombre:</span>
                      <span> {capitalizeData(origen.nombre)}</span>
                    </div>
                    <div>
                      <span className="font-bold">Teléfono:</span>
                      <span> {origen.telefono}</span>
                    </div>
                    <div>
                      <span className="font-bold">
                        Dirección de recolección:
                      </span>
                      <br />
                      <span>
                        {" "}
                        {`${capitalizeData(origen.calle)} ${origen.exterior
                          } ${origen.interior || ""}`}
                      </span>
                      <br />
                      <span>
                        {`${capitalizeData(origen.colonia)}, ${origen.cp
                          }`}
                        <br />
                        {`${capitalizeData(
                          origen.ciudad
                        )}, ${capitalizeData(origen.municipio)}, ${origen.estado
                          }`}
                      </span>
                    </div>
                  </Disclosure.Panel>
                </div>
              )}
            </Disclosure>
            <Disclosure defaultOpen={true} key="item-2">
              {({ open }) => (
                <div className="ml-0">
                  <Disclosure.Button className="flex items-center">
                    <span className="uppercase font-display text-xs">
                      Quien Recibe
                    </span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "-rotate-180" : "rotate-0",
                        "w-4 h-4 transform ml-4"
                      )}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="space-y-1.5">
                    <div>
                      <span className="font-bold">Nombre:</span>
                      <span> {capitalizeData(destino.nombre)}</span>
                    </div>
                    <div>
                      <span className="font-bold">Celular:</span>
                      <span> {destino.telefono}</span>
                    </div>
                    <div>
                      <span className="font-bold">
                        Dirección de recolección:
                      </span>
                      <br />
                      <span>
                        {" "}
                        {`${capitalizeData(destino.calle)} ${destino.exterior
                          } ${destino.interior || ""}`}
                      </span>
                      <br />
                      <span>
                        {`${capitalizeData(destino.colonia)}, ${destino.cp
                          }`}
                        <br />
                        {`${capitalizeData(
                          destino.ciudad
                        )}, ${capitalizeData(destino.municipio)}, ${destino.estado
                          }`}
                      </span>
                    </div>
                  </Disclosure.Panel>
                </div>
              )}
            </Disclosure>
          </div>
        </div>
        {/* end section 3 */}
      </div>
      <div>
        {/* action buttons */}
        <div className="flex-col my-6">
          <div className="flex flex-nowrap space-x-4 mx-4 justify-center">
            <Link
              to="/etiqueta"
              className="btn-shape bg-primary-light-red text-white w-1/2"
            >
              Generar Etiqueta
            </Link>
            <Link
              to="/realizar-factura"
              className={`btn-shape bg-primary-light-red text-white w-1/2 ${styles}`}
            >
              Realizar Factura
            </Link>
          </div>
          <div className="flex flex-nowrap space-x-4 mt-12 justify-center">
            <Link
              to="/"
              className="flex text-primary-light-red w-1/2 items-center justify-center my-4 text-base font-semibold"
            >
              <ArrowLeftIcon className="h-4 w-4 mr-2" />
              <span>Volver al Inicio</span>
            </Link>
            <Link
              to="/"
              className="flex text-primary-light-red w-1/2 items-center justify-center my-4 text-base font-semibold"
            >
              <PaperAirplaneIcon className="h-4 w-4 mr-2 rotate-90" />
              {/* <ArrowLeftIcon className='h-4 w-4 mr-2' /> */}
              <span>Cotizar Otro Envío</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}